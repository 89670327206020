import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class FloodInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Flood Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Flooding is the most common and costly natural disaster in Louisiana. However, there is not coverage for flooding in standard homeowners or renters policies. Contact us to make sure you have the right coverage!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Louisiana neighborhood houses flooded" />
        <Callout
          title="Flood Insurance"
          content="Flooding is the most common and costly natural disaster in Louisiana. However, there is no coverage for flooding in standard homeowners or renters policies. Instead, coverage is acquired through a separate policy from the National Flood Insurance Program (NFIP). The protection and safety of your family and property is paramount, so considering flood insurance is usually worth it."
        />
        <div className="site-body page-content">
          <h2>What Flood Insurance Usually Covers</h2>
          <p>
          <ul>
          <li>Electrical, plumbing systems, furnaces, water heaters, heat pumps and sump pumps.</li>
          <li>Refrigerators, cooking stoves and built-in appliances such as dishwashers.</li>
          <li>Permanently installed carpeting over an unfinished floor, paneling, wallboard, bookcases and cabinets. The damage must be directly caused by flood water. Cabinets that were not damaged by flood water are not covered, even if they match cabinets that were damaged by flood water.</li>
          <li>Foundation walls, anchorage systems and staircases attached to the building. There is an exclusion for “loss caused directly by earth movement even if the earth movement is caused by flood.” </li>
          <li>A detached garage, used for limited storage or parking. Up to 10 percent of the building coverage limit can be used, but will reduce the total amount of building coverage available.</li>
          <li>Fuel tanks and the fuel in them, solar energy equipment and well water tanks.</li>
          <li>Personal belongings such as clothing, furniture and electronic equipment.</li>
          <li>Portable and window air conditioners (easily moved or relocated), clothes washers and dryers.</li>
          <li>Certain valuable items such as original artwork and furs (up to $2,500.)</li>
          <li>Food freezers and the food in them.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="90% of all natural disasters in the United States involve some type of flooding."
            source="FEMA"
          />
          <h2>What Flood Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Damage caused by moisture, mildew or mold that could have been avoided by the property owner or which are not attributable to the flood.</li>
          <li>Damage caused by earth movement, even if the earth movement is caused by flood.</li>
          <li>Additional living expenses, such as temporary housing, while the building is being repaired or is unable to be occupied.</li>
          <li>Loss of use or access of the insured property.</li>
          <li>Property and belongings outside of an insured building such as trees, plants, wells, septic systems, walks, decks, patios, fences, seawalls, hot tubs and swimming pools.</li>
          <li>Currency, precious metals, and valuable papers such as stock certificates.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Flood Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default FloodInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "flood-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-23.jpg" }) {
      ...mainBg
    }
  }
`;
